<template>
    <!--====== PART START ======-->
    
    <header class="appie-header-area appie-sticky bgNav">
        <div class="container">
            <div class="header-nav-box header-nav-box-6">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                        <div class="appie-logo-box">
                            <a href="/">
                                <img src="@/assets/images/Sahasrablack.png" alt="" class="logoView">
                            </a>
                        </div>
                    </div>
                    <!-- <div class="col-lg-10  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                        <div class="appie-btn-box" style="text-align:right">
                            <div class="appie-header-main-menu">
                            <nav-items :menuItems="menuItems"/>
                        </div>
                            <a class="login-btn" href="#"><i class="fal fa-user"></i> Login</a>
                            <a class="main-btn ml-30" href="#">Get Started</a>
                            <div class="toggle-btn ml-30 canvas_open d-lg-none d-block">
                                <i class="fa fa-bars" @click="showSidebar"></i>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </header>
    
    <!--====== PART ENDS ======-->

</template>

<script>
// import NavItems from '../NavItems.vue'
export default {
     props:{
      menuItems:{
          type: Array,
          required: true,
      },
      nasted:{
            type:Boolean,
            default:true
        }  
    },
	components: {},
             mounted() {
        document.addEventListener('scroll', this.stickMenu)
    },
    methods:{
        showSidebar(e){
            this.$emit("toggleSidebar",e)
        },
        stickMenu(){
        const result = document.querySelector('.appie-sticky')
           if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                result.classList.add('sticky')
            } else {
                result.classList.remove('sticky')
            }
        }
    }

}
</script>

<style>
.bgNav {
    background-size: 100%;
}

@media screen and (orientation:landscape) {
    .logoView {
    }
}
@media screen and (orientation:portrait) {
    .logoView {
        margin-left: -10px !important;
    }
}
</style>