<template>
      <!--====== APPIE HERO PART START ======-->
    
    <section class="" style="height: 100vh;, justify-content: center;">
        <div class="" style="background: white;">
            <div class="row align-items-center newbg">
                <div class="sliderArea">
                    <slider animation="fade">
                        <slider-item
                            v-for="(i, index) in text"
                            :key="index"
                            style="background: white;"
                            @click="changeindex(1)"
                        >
                            <span class="sliderText">{{i}}</span>
                        </slider-item>
                    </slider>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider' 
export default {
    data() {
        return {
            list: [
                { backgroundColor: '#3f51b5', width: '100%', height: '100%' },
                { backgroundColor: '#eee', width: '100%', height: '100%' },
                { backgroundColor: '#f44336', width: '100%', height: '100%' },
            ],
            text: [
                'Drive innovation & scale growth with technology to deliver value creation',
                'Combine innovation & industry expertise with the strength of top platform to create broader impact',
                'Implement innovative technology & creative practices to transform your business'
            ]
        }
    },
    components: {
        Slider,
        SliderItem
    },
    methods: {
        changeIndex(index) {
      this.sliderValue = index;
        }
    }
}
</script>

<style>
@media screen and (orientation:portrait) {
    .sliderArea {
        margin-top: 40px !important;
        margin-bottom: 80px !important;
    }
    .expertText {
        font-size: 1rem !important;
        margin-top: 60px;
    }
    .contactExpert {
        text-align: center;
        background: #d3d3d3;
        height: 30vh;
    }
    .sliderText {
        font-size: 1.3rem !important;
        margin-left: 12vw !important;
        margin-right: 12vw !important;
        margin-top: 10vh !important;
        text-align: center;
    }
    .slider-icon {
        border-left: 2px solid black !important;
        border-bottom: 2px solid black !important;
    }
    .slider-btn-left {
        background: white !important;
    }
    .slider-btn-right {
        background: white !important;
    }
    .newbg {
        /* background-image: url("/assets/images/homepage.png"); */
        background-size: 170% 90%;
        background-repeat: no-repeat;
        background-position: center;
        height: 90vh;
        padding-top: 100px;
    }
    .squarebg {
        -moz-box-shadow: 0 5px 5px #ccc;
        -webkit-box-shadow: 0 5px 5px #ccc;
        box-shadow: 0 5px 5px #ccc;
    }
    .festival {
        margin-left: auto;
        margin-right: auto;
        width: 40% !important;
        justify-content: center;
}
    .center {
        margin-top: 200px !important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
}
    .newfont {
        font-family: 'Hanson_Bold' !important;
        font-size: 36px;
        margin-top: 20px !important;
        margin-bottom: 0px !important;
    }
    .mobilespace {
        visibility: hidden;
        display: none;
    }
    .row {
        --bs-gutter-x: 0rem !important;
        margin-right: none !important;
    }
}
@media screen and (orientation:landscape) {
    .slider {
        height: 380px !important;
    }
    .sliderArea {
        padding-top: 100px !important;
        margin-bottom: 60px !important;
        height: 40vh;
        background: white;
    }
    .expertText {
        font-size: 1rem !important;
    }
    .sliderText {
        font-size: 2rem !important;
        margin-top: 10vh !important;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .slider-icon {
        border-left: 2px solid black !important;
        border-bottom: 2px solid black !important;
    }
    .newbg {
        /* background-position: center; */
        background-size: 100%;
        /* background-repeat: no-repeat; */
        height: 100vh;
    }
    .margin-left {
        margin-left: 30px;
        padding-top: 10px !important;
    }
    .squarebg {
        -moz-box-shadow: 0 5px 5px #ccc;
        -webkit-box-shadow: 0 5px 5px #ccc;
        box-shadow: 0 5px 5px #ccc;
    }
    .festival {
        margin-left: auto;
        margin-right: auto;
        width: 0% !important;
        justify-content: center;
}
    .center {
        position: absolute;
        bottom: 50px;
        margin-left: 49%;
        width: 3%;
}
.newfont {
    font-family: 'Hanson_Bold' !important;
    margin-left: 30px;
    margin-top: 120px !important;
    font-size: 90px !important;
    line-height: 100px !important;
    text-align: center;
    margin-bottom: 50px !important;
}
.mobilespace {
    display: block;
}
}

</style>