<template>
  <!--====== APPIE FEATURES PART START ======-->
    <section class="" id="" :style="{'background-image': 'url(' + require('../../assets/images/BG.png') + ')','height': '85vh', 'justify-content': 'center'}">
        <div class="container">
            <div class="">
                    <div class="tab-content" >
                            <div :class="[selectedTab==='setting'?'active show':'']"  class="tab-pane fade "  role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-lg-2 aboutView aboutViewTop">
                                        <div class="appie-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img class="logo" src="@/assets/images/hglogo.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class ="col-lg-1">
                                    </div>
                                    <div class="col-lg-7 aboutView">
                                        <div style="margin-left: 20px; margin-right: 20px;" class="appie-features-content wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <h2 class="title" style="font-weight: bold; color: black;">ABOUT US</h2>
                                            <p style="color: black;">With the current viral hidden gems phenomenon, 
                                            <br> <b> Hunger Gems</b>, an online platform for curated culinary gems, intend to help curate culinary gems so people can discover the best culinary gems more easily.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FEATURES PART ENDS ======-->
</template>

<script>
export default {
    data(){
        return{
            selectedTab:'setting',
            switchPlan:true,
        }
    },
    methods:{
         selectFeature(name){
            this.selectedTab=name
        },
    }
}
</script>

<style>

@media screen and (orientation:portrait){
    .newbgabout {
        background-size: 250% 250%;
        background-repeat: no-repeat;
        background-position: center;
}
    .logo {
        width: 40%;
    }
    .aboutView {
        margin-top: 20px !important;
    }
    .aboutViewTop {
        margin-top: 50px !important;
    }
}
@media screen and (orientation:landscape){
    .newbgabout {
        background-size: 120% 250%;
        background-repeat: no-repeat;
        background-position: right;
    
}   
    .logo {
        width: 80% !important;
    }
    .aboutView {
        margin-top: 200px !important;
    }
}
.aboutbottom {
    padding-left: 9.2vw;
    height: 9vw;
}
</style>