<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area" style="height: 10vh;">
    <!-- <img src="@/assets/images/line.png" style="padding-left: 20px; padding-right: 20px;"> -->
    <div class="">
      <div class="row">
        <hr>
        <div class="justify-content-center" style="margin-top: -10px; margin-bottom: 30px; display: flex; color: black;">
          <div class="footer-widget-info" style="text-align: center">
            <!-- <a href="https://instagram.com/sahasraid?igshid=YmMyMTA2M2Y="><i class="fab fa-instagram" style="margin-right:8px; color: black;"></i><span style="color: black;" class="contacttext">sahasraid</span></a>  -->
            <!-- <br> -->
            <a>Copyright © 2023 Sahasra.<br class="mobileFooter"> All rights reserved.</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
@media screen and (orientation:landscape) {
    .mobileFooter {
      display: none;
    }
    .contacttext {
      color: black;
    } 
}
@media screen and (orientation:landscportraitape) {
    .contacttext {
      color: black;
    } 
}

.appie-footer-area{
    background: white !important;
}
</style>