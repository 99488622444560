<template>
  <div style="background: #d3d3d3;">

    <header-home-six @toggleSidebar="toggleSidebar" :menuItems="navs" style="margin-bottom:50px; background: white;"/>

    <hero-home-six />

    <div class="contactExpert appie-hero-content">
      <div class="expertPosition">
        <span style="color: black !important;" class="expertText"><b>Contact us</b></span>
        <br>
        <ul>
          <li style="margin-left: 10px;"><a href="mailto:hello@sahasra.id"><i class="fab"></i>hello@sahasra.id</a></li>
        </ul>
      </div>
    </div>

    <footer-home-one />
    
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
// import blogImgFour from "@/assets/images/blog-4.jpg";
// import blogImgFive from "@/assets/images/blog-5.jpg";
// import blogImgSix from "@/assets/images/blog-6.jpg";
// import blogImgSeven from "@/assets/images/blog-7.jpg";
import video_bg_thumb from "@/assets/images/video-thumb-1.jpg";
// import FeaturesHomeOne from "../HomeOne/FeaturesHomeOne.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
// import PriceingHomeOne from "../HomeOne/PriceingHomeOne.vue";
// import ProjectHomeOne from "../HomeOne/ProjectHomeOne.vue";
// import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
// import BlogHomeThree from "../HomeThree/BlogHomeThree.vue";
// import DownloadHomeThree from "../HomeThree/DownloadHomeThree.vue";
// import CounterHomeTwo from "../HomeTwo/CounterHomeTwo.vue";
// import ServicesHomeTwo from "../HomeTwo/ServicesHomeTwo.vue";
// import VideoPlayHomeTwo from "../HomeTwo/VideoPlayHomeTwo.vue";
import HeaderHomeSix from "./HeaderHomeSix.vue";
import HeroHomeSix from "./HeroHomeSix.vue";
// import CarouselHomeSixVue from './CarouselHomeSix.vue';
export default {
  components: {
    HeaderHomeSix,
    HeroHomeSix,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      video_bg_thumb: video_bg_thumb,
      navs: [
        {
          name: "home",
          section: "#"
        },
        {
          name: "About us",
          section: "#about"
        },
        {
          name: "Join as Tenant",
          section: "#jointenant"
        },
        {
          name: "contact",
          section: "#footer",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>

html{
  scroll-behavior: smooth;
}
@media screen and (orientation:portrait) {
  .offset {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}
}
@media screen and (orientation:landscape) {
  .expertPosition {
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .contactExpert {
    position: relative;
    text-align: center;
    background: #d3d3d3;
    height: 35vh;
    padding: 100px;
    }
  .offset {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
}
}


</style>